// NOTE: without destructuring will cause failure to fetch api on production

import { ItemVariants } from 'types/schema';

// eslint-disable-next-line prefer-destructuring
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: string) => {
  // bypass if a browser/extendsion blocks gtag
  if (!('gtag' in window)) {
    return;
  }

  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
export interface GtagItem {
  id?: string | number;
  name?: string;
  list_name?: string;
  brand?: string;
  category?: string;
  variant?: ItemVariants[] | string;
  list_position?: number;
  quantity?: number;
  price?: string | number;
}

export interface GtagPromotion {
  id: string;
  name: string;
}

export interface GtagService {
  fullname: string;
  gender?: string;
  email: string;
  phone: string;
  address: string;
  method: string;
  appointment_time?: string;
  delivery_address?: string;
  items: GtagItem[];
}

export interface GtagServiceSpa extends GtagService {
  note?: string;
  type?: string;
}

export interface GtagServiceConsign extends GtagService {
  city?: string;
  district?: string;
}

export type GtagAction =
  | 'select_content'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'view_promotion'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'set_checkout_option'
  | 'purchase'
  | 'refund'
  | 'book_auth_service'
  | 'book_consign_service'
  | 'book_spa_service';

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  values,
}: {
  action: GtagAction;
  values: Record<string, any>;
}) => {
  // bypass if a browser/extendsion blocks gtag
  if (!('gtag' in window)) {
    return;
  }

  if (GA_TRACKING_ID) {
    window.gtag('event', action, values);
  }

  if (process.env.GA_DEBUG_ENABLE?.toString() === 'true') {
    // eslint-disable-next-line no-console
    console.log('gaEvent', { action, values });
  }
};

export const gtagSelectContentEvent = (values: {
  content_type: 'product';
  items: GtagItem[];
}) => event({ action: 'select_content', values });

export const gtagViewItemEvent = (values: { items: GtagItem[] }) =>
  event({ action: 'view_item', values });

export const gtagAddToCartEvent = (values: { items: GtagItem[] }) =>
  event({ action: 'add_to_cart', values });

export const gtagRemoveFromCartEvent = (values: { items: GtagItem[] }) =>
  event({ action: 'remove_from_cart', values });

export const gtagViewPromotionEvent = (values: { promotions: GtagPromotion }) =>
  event({ action: 'view_promotion', values });

export const gtagBeginCheckoutEvent = (values: {
  items: GtagItem[];
  coupon?: string;
}) => event({ action: 'begin_checkout', values });

export const gtagCheckoutProgressEvent = (values: {
  items: GtagItem[];
  total: number;
  coupon?: string;
}) => event({ action: 'checkout_progress', values });

export const gtagCheckoutOptionEvent = (values: {
  checkout_step: number;
  checkout_option: string;
  value: number;
}) => event({ action: 'set_checkout_option', values });

export const gtagPurchaseEvent = (values: {
  transaction_id?: string;
  affiliation?: string;
  value: number;
  tax?: number;
  currency: string;
  shippingFee: number;
  items: GtagItem[];
  paymentMethod?: string;
  shippingAddress?: string;
}) => event({ action: 'purchase', values });

export const gtagRefundEvent = (values: {
  transaction_id: string;
  affiliation?: string;
  value: number;
  tax?: number;
  currency: string;
  shipping: number;
  items: GtagItem[];
}) => event({ action: 'refund', values });
