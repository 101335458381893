import React from 'react';
import { ModalProps, Modal } from 'components/Modal';

export interface PageModalProps
  extends Omit<ModalProps, 'shouldCloseOnOverlayClick'> {}

export const PageModal: React.FC<PageModalProps> = ({
  children,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} shouldCloseOnOverlayClick={false}>
      {children}
    </Modal>
  );
};
