import React, { HTMLAttributes } from 'react';
import tw from 'twin.macro';

interface Props extends HTMLAttributes<HTMLDivElement> {}

// TODO: make it resize within the body and allocate spare space for footer
export const PageModalHeadWrapper: React.FC<Props> = ({
  children,
  ...rest
}) => {
  return (
    <div {...rest} css={tw`flex flex-col w-full h-full`}>
      {children}
    </div>
  );
};
