import React, { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Content = styled.div`
  ${tw`px-4 py-4 overflow-y-auto w-full flex-1`};
`;

export const PageModalContent: React.FC<Props> = ({ children, ...rest }) => {
  return <Content {...rest}>{children}</Content>;
};
