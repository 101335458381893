import { useState, useCallback } from 'react';

export interface UseSubmittingProps {
  defaultIsSubmitting?: boolean;
  onDone?(): void;
  onSubmit?(): void;
}

export function useSubmitting(props: UseSubmittingProps = {}) {
  const { onDone: onDoneProp, onSubmit: onSubmitProp } = props;

  const [isSubmitting, setIsSubmitting] = useState(
    props.defaultIsSubmitting || false,
  );

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    if (onDoneProp) {
      onDoneProp();
    }
  }, [onDoneProp]);

  const onDone = useCallback(() => {
    setIsSubmitting(false);
    if (onSubmitProp) {
      onSubmitProp();
    }
  }, [onSubmitProp]);

  return {
    isSubmitting: !!isSubmitting,
    onDone,
    onSubmit,
  };
}

export type UseSubmittingReturn = ReturnType<typeof useSubmitting>;
