import React from 'react';
import { callAllHandlers } from 'utils/function';
import { CloseButton, CloseButtonProps } from 'components/CloseButton';
import { useModalContext } from 'components/Modal';
import tw from 'twin.macro';

interface Props extends Omit<CloseButtonProps, 'label' | 'size'> {
  label?: string;
}

export const PageModalCloseButton = ({
  label = 'Đóng',
  onClick,
  ...props
}: Props) => {
  const { onClose } = useModalContext();

  return (
    <div
      css={tw`absolute flex items-center justify-center left-0 top-0 h-14 z-10 pl-2`}
    >
      <CloseButton
        size="base"
        label={label}
        {...props}
        onClick={callAllHandlers(onClick, () => {
          if (onClose) {
            onClose();
          }
        })}
      />
    </div>
  );
};
