import cookie from 'js-cookie';
import { CartItem, Session, Product, Seller, ServiceFee } from 'types/schema';
import { mapOptionVariants, getPrimaryProductShowcase } from './product';

export const CART_COOKIE_KEY = 'artzy_sc';
export const CART_COOKIE_EXPIRED_DAYS = 365;

export function addCartSession(id: string) {
  cookie.set(CART_COOKIE_KEY, id, {
    expires: CART_COOKIE_EXPIRED_DAYS,
  });
}

export function updateCartSession(id: string) {
  addCartSession(id);
}

export function removeCartSession() {
  cookie.remove(CART_COOKIE_KEY);
}

export function mapCartItems(items: CartItem[]) {
  return items.map((c) => {
    const cartItem = c as Required<CartItem>;
    const session = cartItem.session as Required<Session>;

    const cartItemId = cartItem.id;
    const sessionSharingId = session.sharing_id;
    const sessionId = cartItem.session_sharing_id;

    const product = session.product as Required<Product>;
    const seller = product.seller as Required<Seller>;
    const sellerId = seller.id;
    const sellerSlug = seller.slug;
    const sellerName = seller.name;
    const thumbnail = getPrimaryProductShowcase(product)?.photo_url || '';

    const variants = mapOptionVariants(session).map(
      ({ name, variantName }) => ({
        name: variantName,
        value: name,
      }),
    );

    const quantity = session.quantity || 1;

    return {
      price: (session.price || 0) / (session.quantity || 1),
      originalPrice: session.original_unit_price,
      cartItemId,
      sessionSharingId,
      sessionId,
      sellerId,
      sellerSlug,
      sellerName,
      variants,
      quantity,
      product,
      thumbnail,
      nextDiscount: c.session?.next_discount,
      quantityEditable: session.quantity_editable,
    };
  });
}

export function sumTotalCartPrices(items: CartItem[], serviceFee: ServiceFee) {
  const priceBeforeDiscount = items.reduce((a, c) => {
    // @ts-ignore
    const price = c.session?.original_unit_price || 0;
    const quantity = c.session?.quantity || 1;

    return a + price * quantity;
  }, 0);

  const totalRealPrice = items.reduce((a, c) => {
    const price = c.session?.price || 0;

    return a + price;
  }, 0);

  const totalVoucherDiscount = (serviceFee.vouchers || []).reduce((a, c) => {
    if (c.discount) {
      return a + c.discount;
    }

    return a;
  }, 0);

  const totalCostWithoutShippingFee = totalRealPrice - totalVoucherDiscount;

  const totalCost =
    totalCostWithoutShippingFee + (serviceFee?.final_shipping_fee as number);

  const totalDiscountProduct = priceBeforeDiscount - totalRealPrice;

  const totalDiscount = totalDiscountProduct + totalVoucherDiscount;

  return {
    totalDiscount,
    totalRealPrice,
    totalVoucherDiscount,
    totalCostWithoutShippingFee,
    priceBeforeDiscount,
    totalDiscountProduct,
    totalCost: totalCost || priceBeforeDiscount,
  };
}
