import React, { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';
import { keyframes } from 'styled-components';
import { useModalContext } from 'components/Modal';

const slide = keyframes`
    from {
        transform: translateY(200px);
        opacity: 0;
      }
      to {
        transform: translateY(0px);
        opacity: 1;
    }
`;

const WithAnimation = styled.div<{
  isOpen: boolean;
}>`
  animation: ${slide} 0.3s ease;
`;

const Container = styled(WithAnimation)`
  ${tw`flex flex-col transition transition-all duration-300 fixed inset-0 justify-between bg-white sm:container`};
  transform: ${(props) => {
    return props.isOpen ? 'translateY(0px)' : 'translateY(200px)';
  }};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const PageModalBody: React.FC<Props> = ({ children, ...rest }) => {
  const { isOpen } = useModalContext();

  return (
    <Container isOpen={isOpen} {...rest}>
      {children}
    </Container>
  );
};
