import { useState, useCallback, useEffect } from 'react';
import cookie from 'js-cookie';
import { CART_COOKIE_KEY, addCartSession, removeCartSession } from 'utils/cart';
import APIs from 'libs/apis';
import {
  CreateCartItemRequest,
  CartItem,
  ServiceFee,
  Session,
  Voucher,
} from 'types/schema';
import { useToast } from 'components/Toast';
import { createContext } from 'utils/react-helpers';
import { useSubmitting } from 'hooks/useSubmitting';
import { gtagAddToCartEvent, gtagRemoveFromCartEvent } from 'libs/gtag';
import { captureException } from 'libs/logger';

interface CartContextValues {
  id: string;
  selectedVoucher?: Voucher | null;
  setSelectedVoucher: (v: Voucher | null) => void;
  isLocked: boolean;
  addItem: (
    body: CreateCartItemRequest,
    callack?: { onSuccess?: () => void; onError?: () => void },
  ) => void;
  isVoucherLocked: boolean;
  setIsVoucherLocked: (v: boolean) => void;
  isUpdatingCart: boolean;
  isFetchingCart: boolean;
  cartItems: CartItem[];
  clearCart: () => void;
  fetchCartDetailInBackground: () => void;
  removeItem: ({
    sharingId,
    itemId,
  }: {
    sharingId: string;
    itemId: string;
  }) => void;
  updateItem: (data: {
    sessionId: string;
    productSlug: string;
    body: Session;
  }) => Promise<void>;
  serviceFee: ServiceFee;
  lockCart: () => void;
  unlockCart: () => void;
  voucherError: boolean;
}

const [Provider, useCartContext] = createContext<CartContextValues>();

export const CartContextProvider: React.FC = ({ children }) => {
  const [id, setId] = useState(cookie.get(CART_COOKIE_KEY) || '');
  const [voucherError, setVoucherError] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [serviceFee, setServiceFee] = useState<ServiceFee>({});
  const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
  const { isSubmitting: isUpdatingCart, onDone, onSubmit } = useSubmitting();
  const [isVoucherLocked, setIsVoucherLocked] = useState(false);
  const {
    isSubmitting: isFetchingCart,
    onDone: onFetchCartDone,
    onSubmit: onFetchCart,
  } = useSubmitting({ defaultIsSubmitting: true });

  const toast = useToast();

  const addItem = async (
    body: CreateCartItemRequest,
    callback?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    const { onSuccess, onError } = callback || {};
    onSubmit();
    try {
      let currentSesstion: Session | undefined = undefined;
      if (!id) {
        const {
          data: { sharing_id, cart_items = [] },
        } = await APIs.Cart.createCart({
          cart_items: [body],
        });

        currentSesstion = cart_items[0]?.session;
        if (sharing_id) {
          setId(sharing_id);
          addCartSession(sharing_id);
        }
      } else {
        const {
          data: { session },
        } = await APIs.Cart.addItem(id, body);

        currentSesstion = session;
      }
      // tracking events
      if (currentSesstion) {
        const { product, session_items = [], item_variants } = currentSesstion;
        const quantity = session_items.length || 0;
        gtagAddToCartEvent({
          items: [
            {
              id: product?.id,
              name: product?.name,
              quantity,
              variant: item_variants,
              category: product?.category?.name,
              brand: product?.seller?.name,
              price: (product?.unit_cost || 0) * quantity,
            },
          ],
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      await fetchCartDetail();
    } catch (e) {
      toast({ title: 'Thêm sản phẩm vào giỏ hàng không thành công.' });
      captureException(e);
      if (onError) {
        onError();
      }
    } finally {
      onDone();
    }
  };

  const removeItem = async (params: { sharingId: string; itemId: string }) => {
    onSubmit();

    try {
      await APIs.Cart.removeItem(params);
      fetchCartDetailInBackground();

      gtagRemoveFromCartEvent({ items: [{ id: params.itemId }] });
    } catch {
      toast({ title: 'Xoá sản phẩm không thành công.' });
    } finally {
      onDone();
    }
  };

  const fetchCartDetail = useCallback(async () => {
    if (!id) {
      onFetchCartDone();

      return;
    }
    onFetchCart();
    try {
      const {
        data: { cart_items, is_locked = false, voucher },
      } = await APIs.Cart.getCartDetail(id);
      setCartItems(cart_items || []);
      if (id) {
        if (!selectedVoucher && cartItems.length) {
          setSelectedVoucher(voucher as Voucher);
        }
        try {
          const { data: serviceFee } = await APIs.Cart.getServiceFee({
            sharingId: id,
            voucherId: !voucherError
              ? (selectedVoucher?.id as number)
              : undefined,
          });
          const hasErrorVoucher = serviceFee?.vouchers?.some(
            (v) => !v?.discount || v?.error,
          );
          if (hasErrorVoucher) {
            setVoucherError(true);
          } else {
            setServiceFee(serviceFee);
          }
        } catch {
          setVoucherError(true);
        } finally {
          setIsVoucherLocked(false);
        }
      }
      setIsLocked(is_locked);
    } finally {
      onFetchCartDone();
    }
  }, [id]);

  const fetchCartDetailInBackground = useCallback(async () => {
    if (!id) {
      return;
    }

    onSubmit();
    try {
      const {
        data: { cart_items, is_locked = false, voucher },
      } = await APIs.Cart.getCartDetail(id);
      setCartItems(cart_items || []);
      if (id) {
        if (cartItems.length && !selectedVoucher) {
          setSelectedVoucher(voucher as Voucher);
        }
        try {
          const { data: serviceFee } = await APIs.Cart.getServiceFee({
            sharingId: id,
            voucherId: !voucherError
              ? (selectedVoucher?.id as number)
              : undefined,
          });
          const hasErrorVoucher = serviceFee?.vouchers?.some(
            (v) => !v?.discount || v?.error,
          );
          if (hasErrorVoucher) {
            setVoucherError(true);
          } else {
            setServiceFee(serviceFee);
          }
        } catch {
          setVoucherError(true);
        } finally {
          setIsVoucherLocked(false);
        }
      }
      setIsLocked(is_locked);
    } finally {
      onDone();
    }
  }, [id, selectedVoucher?.id, voucherError]);

  useEffect(() => {
    fetchCartDetail();
  }, [fetchCartDetail]);

  useEffect(() => {
    resetServiceFee(id);
  }, [id]);

  useEffect(() => {
    setVoucherError(false);
    if (selectedVoucher?.id && id) {
      APIs.Cart.updateCart({
        sharingId: id,
        voucherId: Number(selectedVoucher?.id).toString(),
      });
    }
  }, [id, selectedVoucher?.id]);

  useEffect(() => {
    if (!cartItems.length) {
      resetVoucher(id);
    }
  }, [id, cartItems.length]);

  const resetVoucher = useCallback(async (sharingId: string) => {
    try {
      if (sharingId) {
        await APIs.Cart.updateCart({
          sharingId,
          voucherId: null,
        });
        const { data: serviceFee } = await APIs.Cart.getServiceFee({
          sharingId,
        });
        setServiceFee(serviceFee);
      }
    } catch (error) {
      captureException(error);
    } finally {
      setSelectedVoucher(null);
    }
  }, []);

  const resetServiceFee = useCallback(async (sharingId: string) => {
    if (sharingId) {
      try {
        const { data: serviceFee } = await APIs.Cart.getServiceFee({
          sharingId,
        });
        setServiceFee(serviceFee);
      } catch (error) {
        captureException(error);
      }
    }
  }, []);

  const clearCart = useCallback(() => {
    removeCartSession();
    setId('');
    setCartItems([]);
  }, []);

  const lockCart = useCallback(async () => {
    try {
      onFetchCart();
      await APIs.Cart.lockCart(id);
      setIsLocked(true);
    } catch (err) {
      captureException(err);
    } finally {
      onFetchCartDone();
    }
  }, [id]);

  const unlockCart = useCallback(async () => {
    try {
      onFetchCart();
      await APIs.Cart.unlockCart(id);
      setIsLocked(false);
    } catch (err) {
      captureException(err);
    } finally {
      onFetchCartDone();
    }
  }, [id]);

  const updateItem = useCallback(
    async (reqData: {
      sessionId: string;
      productSlug: string;
      body: Session;
    }) => {
      try {
        const { data } = await APIs.Session.updateSession(reqData);
        setCartItems((pre) =>
          pre.map((item) => {
            if (item.session?.id === data?.id) {
              return { ...item, session: data };
            }

            return item;
          }),
        );
        fetchCartDetailInBackground();
      } catch (err) {
        captureException(err);
      }
    },
    [fetchCartDetailInBackground],
  );

  return (
    <Provider
      value={{
        isVoucherLocked,
        setIsVoucherLocked,
        voucherError,
        id,
        selectedVoucher,
        setSelectedVoucher,
        addItem,
        isUpdatingCart,
        removeItem,
        isFetchingCart,
        cartItems,
        clearCart,
        fetchCartDetailInBackground,
        serviceFee,
        isLocked,
        lockCart,
        unlockCart,
        updateItem,
      }}
    >
      {children}
    </Provider>
  );
};

export { useCartContext };
