import React from 'react';
import tw from 'twin.macro';
import { useModalContext } from 'components/Modal';
import { Heading } from 'components/typography';

export const PageModalHeader: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => {
  const { labelledby } = useModalContext();

  return (
    <header
      {...rest}
      css={tw`h-14 flex px-4 items-center justify-center px-10 border-b border-gray-300 flex-none w-full text-center bg-white`}
    >
      <Heading as="h2" id={labelledby} css={tw`text-lg`} isSerif truncate>
        {children}
      </Heading>
    </header>
  );
};
