import React, { HTMLAttributes } from 'react';
import tw from 'twin.macro';

interface Props extends HTMLAttributes<HTMLElement> {}

export const PageModalFooter: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <footer {...rest} css={tw`p-4 w-full flex-none`}>
      {children}
    </footer>
  );
};
