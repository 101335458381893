import React from 'react';
import { LinkTextButton, LinkTextButtonProps } from 'components/Button';
import tw from 'twin.macro';

export const PageModalDoneButton = (
  props: Omit<LinkTextButtonProps, 'children'>,
) => {
  return (
    <div css={tw`absolute flex items-center justify-center right-0 h-14 z-10`}>
      <LinkTextButton {...props}>Xong</LinkTextButton>
    </div>
  );
};
